.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 151px;
  flex-direction: column;
}

.title {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  // margin-bottom: 15px;
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.container li {
  margin-top: 11px;
  line-height: 25px;
}
@media screen and (max-width: 1220px) {
  .container {
    padding: 15px 60px;
  }
  .subtitle {
    font-size: 19px;
  }
  .block_right div {
    font-size: 16px;
  }
}
@media screen and (max-width: 980px) {
  .container {
    padding: 15px 44px;
  }
  // .subtitle {
  //   font-size: 18px;
  //   margin-bottom: 0px;
  // }
  // .block_right div {
  //   // font-size: 15px;
  // }
  .block_right {
    display: none;
  }
  .block_left {
    width: 100%;
  }
}

@media screen and(max-width: 680px) {
  .container {
    padding: 15px 30px;
  }
}
