
@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-VariableFont_slnt\,wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/static/Inter-Bold-slnt=0.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/static/Inter-ExtraBold-slnt=0.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #181609;
}

body.hide_scroll {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* div::-webkit-scrollbar {
  border-radius: 8px;
  width: 8px;
  margin-right: 5px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #cdcdcd;
  margin-right: 5px;
} */

.container_page {
  height: 100vh;
  width: 100vw;
  overflow: auto;
}

.container_page.hide_scroll {
  overflow: hidden;
}

.map-view {
  width: 100vw;
  height: 100vh;
}

.swiper-button-next, .swiper-button-prev {
  background: rgba(244, 244, 244, 0.8);
  backdrop-filter: blur(4px) !important;
  width: 50px !important;
  height: 50px !important;
  box-shadow: 0px 7px 10px -9px !important;
  border-radius: 50% !important;
  color: #828282 !important;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 16px !important;
  font-weight: 900 !important;
}

.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  /* background: #CDCDCD !important; */
}

.swiper-pagination-bullet-active {
  background: #f54a3f !important;
}

.swiper-button-disabled {
  display: none !important;
}

.search_container {
  width: 400px;
}

.searchbar_mobile {
  flex: 1;
  margin-right: 44px;
}

.delete {
  background-image: url("../images/delete.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 14px;
  height: 18px;
  cursor: pointer;
  margin-right: 13px;
}

.delete:hover {
  opacity: 0.8;
  transition: all 0.2s;
}

.basket {
  position: absolute;
  width: 300px;
  height: 100%;
  background-color: white;
  z-index: 21;
  top: 0;
  right: 0;
  padding: 20px 16px 0 16px;
}

.empty_block {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #828282;
}

.marker-cluster {
  width: 36px !important;
  height: 36px !important;
  background: #F54A3F !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.marker-cluster div {
  color: white;
  width: 36px !important;
  height: 36px !important;
  background: none !important;
  font-family: Inter !important;
  font-style: normal;
  font-weight: 900 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  border-radius: 50%;
  margin: 0 !important;
}

.title {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.sub_title {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  margin-top: 10px;
}

.value {
  font-size: 14px;
  margin-top: 4px;
}

.leaflet-popup-content {
  width: 290px !important;
}

.block_info_address {
  width: 130px;
}

.leaflet-popup {
  left: 0px !important;
  bottom: 2px !important;
  /* border: 1px solid #CDCDCD; */
  box-sizing: border-box;
  border-radius: 8px 8px 8px 0px !important;
}

.leaflet-popup-tip {
  display: none !important;
}

.leaflet-popup-content-wrapper {
  box-shadow: none;
  border: 1px solid #CDCDCD;
  border-radius: 8px 8px 8px 0px !important;
}

.b24-widget-button-wrapper.b24-widget-button-position-bottom-right, .b24-widget-button-wrapper.b24-widget-button-position-bottom-middle, .b24-widget-button-wrapper.b24-widget-button-position-bottom-left {
  z-index: 18;
}

.spinner{
  z-index: 20 !important;
}

@media screen and (max-width: 980px) {
  .search_container {
    display: none;
  }
}

@media screen and (max-width: 680px) {
  .swiper-button-next, .swiper-button-prev {
    width: 44px !important;
    height: 44px !important;
  }
  .searchbar_mobile {
    margin-right: 27px;
  }
}

@media screen and (max-width: 390px) {
  .searchbar_mobile {
    margin-right: 15px;
  }
}